import React from 'react';

import mapModifiers from 'utils/functions';

export interface TextProps {
  modifiers?: (GeneralTextStyle | 'green' | 'gold')[];
  font?: 'androgyne';
  type?: 'p' | 'span' | 'div';
}

const Text: React.FC<TextProps> = ({
  modifiers,
  font,
  type = 'p',
  children,
}) => {
  const Element = type;
  return (
    <Element className={mapModifiers('a-text', modifiers, font)}>
      {children}
    </Element>
  );
};

export default Text;
