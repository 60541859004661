/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React from 'react';

import Icon, { IconName } from 'components/Icon';
import Text from 'components/Text';
import mapModifiers from 'utils/functions';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  iconName?: IconName;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  iconName,
  loading,
  children,
  ...props
}) => (
  <button {...props} className="a-button">
    <div className="a-button_wrapper">
      {loading ? (
        <div className="a-button_loading">
          <Icon iconName="loading" />
        </div>
      ) : (
        <>
          {children}
          {iconName && <Icon iconName={iconName} />}
        </>
      )}
    </div>
  </button>
);

interface TextButtons extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  modifiers?: (GeneralTextStyle | 'gold')[];
  font?: 'androgyne';
}

export const TextButton: React.FC<TextButtons> = ({
  modifiers,
  font,
  children,
  ...props
}) => (
  <button {...props} className={mapModifiers('a-textbutton', modifiers)}>
    <Text type="p" modifiers={modifiers} font={font}>
      {children}
    </Text>
  </button>
);

Button.defaultProps = {
  type: 'button',
  disabled: false,
  loading: false,
};

export default Button;
