import * as yup from 'yup';

export const validationSchemaFormSubmit = (isVerifySuccess: boolean) => yup.object({
  phone: yup.string().required().min(10, 'Không đúng định dạng').matches(/^0[1-9][0-9]{8}/g, 'Không đúng định dạng'),
}).when('phone', {
  is: () => true && isVerifySuccess,
  then: yup.object().shape({
    name: yup.string().required('Nhập họ tên'),
    email: yup.string().email('Nhập đúng định dạng'),
    cities: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
    districts: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
    confirm: yup.string().equals(['1'], 'confirm'),
  }),
});

export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${day}/${month}/${year}`;
};
export const formatTime = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
};
