import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { viewsArService } from 'services/request/stat';

const CameraContainer: React.FC = () => {
  const history = useHistory();
  const cameraRef = useRef<HTMLVideoElement>(null);
  const animationRef = useRef<HTMLVideoElement>(null);

  const [autoplayMuted, setAutoplayMuted] = useState(false);

  useEffect(() => {
    let stopCamera: () => void;

    (async () => {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment',
        },
      });

      stopCamera = () => {
        mediaStream.getTracks().forEach((t) => t.stop());
      };

      if (!cameraRef.current) {
        return;
      }

      const cameraEl = cameraRef.current;

      cameraEl.srcObject = mediaStream;
    })();

    return () => {
      if (stopCamera) {
        stopCamera();
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      const animationEl = animationRef.current!;

      if (!animationEl.paused) {
        return;
      }

      try {
        await animationEl.play();
      } catch (e) {
        setAutoplayMuted(true);

        animationEl.muted = true;
        animationEl.play();

        // eslint-disable-next-line no-console
        console.log(`Unmuted auto-play denied: ${e.message}`);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await viewsArService();
    })();
  }, []);

  const unmute = () => {
    animationRef.current!.muted = false;
    setAutoplayMuted(false);
  };

  const handleEnded = () => {
    history.push({
      pathname: '/start',
      search: window.location.search,
    });
  };

  return (
    <div className="p-preview">
      <video
        ref={cameraRef}
        className="preview"
        controls={false}
        playsInline
        autoPlay
        muted
      />
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={animationRef}
        className="frame"
        onEnded={handleEnded}
        controls={false}
        playsInline
        autoPlay
      >
        <source
          src="https://cdn.hoithaoensure.com/static/ensure/plantbase-v11-audio.hevc.mp4"
          type="video/mp4;codecs=hvc1"
        />
        <source
          src="https://cdn.hoithaoensure.com/static/ensure/plantbase-v11-audio.vp9.webm"
          type="video/webm;codecs=vp9"
        />
      </video>
      {autoplayMuted && (
        <button className="btn-unmute" onClick={unmute} type="button">
          <svg stroke="black" fill="white" strokeWidth="0" viewBox="0 0 1024 1024" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
            <path d="M892.1 737.8l-110.3-63.7a15.9 15.9 0 0 0-21.7 5.9l-19.9 34.5c-4.4 7.6-1.8 17.4 5.8 21.8L856.3 800a15.9 15.9 0 0 0 21.7-5.9l19.9-34.5c4.4-7.6 1.7-17.4-5.8-21.8zM760 344a15.9 15.9 0 0 0 21.7 5.9L892 286.2c7.6-4.4 10.2-14.2 5.8-21.8L878 230a15.9 15.9 0 0 0-21.7-5.9L746 287.8a15.99 15.99 0 0 0-5.8 21.8L760 344zm174 132H806c-8.8 0-16 7.2-16 16v40c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16v-40c0-8.8-7.2-16-16-16zM625.9 115c-5.9 0-11.9 1.6-17.4 5.3L254 352H90c-8.8 0-16 7.2-16 16v288c0 8.8 7.2 16 16 16h164l354.5 231.7c5.5 3.6 11.6 5.3 17.4 5.3 16.7 0 32.1-13.3 32.1-32.1V147.1c0-18.8-15.4-32.1-32.1-32.1z" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default CameraContainer;
