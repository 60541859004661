/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import 'App.scss';

import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router, Switch, Route, useLocation,
} from 'react-router-dom';
import {
  TransitionGroup,
  CSSTransition,
} from 'react-transition-group';

import { initGTM } from 'GoogleTagManager';
import { useInAppDetect } from 'hooks/useInAppDetect';
import routes from 'routes';
import { store } from 'store';

initGTM();

const NotFound: React.FC = () => <div>Not Found</div>;

const App: React.FC = () => {
  const inApp = useInAppDetect();
  const location = useLocation();

  if (inApp) {
    return inApp === 'ios' ? (
      <span>
        Vui lòng nhấn và giữ
        {' '}
        <a href={window.location.href}>liên kết này</a>
        {' '}
        trong 02 giây để chuyển đổi trình duyệt phù hợp và trải nghiệm
      </span>
    ) : (
      <span>
        Trình duyệt không khả dụng. Vui lòng nhấn vào
        {' '}
        <a href={`intent://${window.location.host}${window.location.pathname}#Intent;scheme=${window.location.protocol.replace(/\W/g, '')};action=android.intent.action.VIEW;end;`}>liên kết này</a>
        {' '}
        để chuyển đổi trình duyệt phù hợp và trải nghiệm.
      </span>
    );
  }

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Switch location={location}>
          {routes.map((route) => (
            <Route
              exact
              path={route.path}
              key={route.id}
              component={route.component}
            />
          ))}
          <Route exact path="*" component={NotFound} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

export default AppWrapper;
