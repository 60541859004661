/* eslint-disable react/jsx-props-no-spreading */
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Abbott from 'assets/images/Abbott.png';
import Logo from 'assets/logo/logo.svg';
import Button from 'components/Button';
import Image from 'components/Image';
import Input from 'components/Input';
import Text from 'components/Text';

type StaffForm = {
  id: string;
};

const initStaffForm: StaffForm = {
  id: '',
};

const StaffContainer: React.FC = () => {
  const method = useForm<StaffForm>({
    defaultValues: initStaffForm,
    resolver: yupResolver(
      yup.object({
        id: yup.string().required('Vui lòng nhập mã nhân viên'),
      }),
    ),
  });
  const [isSuccess, setIsSuccess] = useState(false);

  const { id } = method.watch();

  const onSubmit = useCallback((values: StaffForm) => {
    const url = `${window.location.origin}/?affiliate=${values.id}`;
    setIsSuccess(true);
    window.navigator.clipboard.writeText(url);
  }, []);

  return (
    <div className="p-staff">
      <div className="container">
        <div className="logo">
          <Image ratio="logo" imgSrc={Logo} alt="logo" />
        </div>

        <div className="description">
          <Text type="div" modifiers={['center']}>
            <Text type="span" modifiers={['white', 'p2', 'uppercase']}>
              chia sẽ liên kết để được tích điểm nhiều hơn
            </Text>
            {isSuccess && (
              <>
                <br />
                <br />
                <Text type="span" modifiers={['mediumSeaGreen', 'p2']}>
                  Copy liên kết thành công
                </Text>
              </>
            )}
          </Text>
        </div>
        <div className="form">
          <FormProvider {...method}>
            <form onSubmit={method.handleSubmit(onSubmit)} noValidate>
              <div className="item">
                <div className="label">
                  <Text type="span" modifiers={['white', 'p2']}>
                    Mã nhân viên
                  </Text>
                </div>
                <Controller
                  name="id"
                  control={method.control}
                  render={({ field }) => (
                    <div className="value">
                      <Input
                        {...field}
                        type="text"
                        id="copyText"
                        onChange={(e) => {
                          if (isSuccess) {
                            setIsSuccess(false);
                          }
                          field.onChange(e.target.value);
                        }}
                      />
                      <ErrorMessage
                        name="id"
                        errors={method.formState.errors}
                        render={({ message }) => (
                          <Text type="span" modifiers={['cardinal', 'p2']}>
                            {message}
                          </Text>
                        )}
                      />
                    </div>
                  )}
                />
              </div>

              {id && (
                <Text type="p" modifiers={['white', 'p3', 'center']}>
                  {`${window.location.origin}/?affiliate=${id}`}
                </Text>
              )}

              <div className="submit">
                <Button type="submit">
                  <Text type="span" modifiers={['white', 'p1', 'uppercase']}>
                    Nhận liên kết
                  </Text>
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
        <div className="bottom adjust-flex">
          <Text type="p" modifiers={['taupeGray']}>
            ENS-C-466-21
          </Text>
          <div className="abbott">
            <Image ratio="abbott" imgSrc={Abbott} alt="Abbott" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffContainer;
