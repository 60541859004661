/* eslint-disable camelcase */
import axiosInstance from 'services/common/instance';

export type RequestOTPParams = {
  phone: string;
}
export type RequestResendOTPParams = RequestOTPParams;

export type RequestVerifyOTPParams = {
  phone: string;
  otp: string;
}

export type OTPResponse = {
  data: null,
  message: string;
}

export type SaveConsumerParams = {
  name: string;
  email: string;
  phone: string;
  city_code: string;
  district_code: string;
  utm_source?: string | null;
  utm_campaign?: string | null;
  utm_content?: string | null;
  utm_medium?: string | null;
  utm_terms?: string | null;
  utm_string?: string | null;
  affiliate?: string | null;
}

export const requestSendOTPService = async (params: RequestOTPParams): Promise<OTPResponse> => {
  const res = await axiosInstance.post('/consumers/otp/send', params);
  return res.data;
};

export const requestResendOTPService = async (params: RequestOTPParams): Promise<OTPResponse> => {
  const res = await axiosInstance.post('/consumers/otp/resend', params);
  return res.data;
};

// TODO: Defined response later
export const requestVerifyOTPService = async (params: RequestVerifyOTPParams): Promise<any> => {
  const res = await axiosInstance.post('/consumers/otp/verify', params);
  return res.data;
};

export const requestSaveConsumerService = async (params: SaveConsumerParams): Promise<any> => {
  const res = await axiosInstance.post('/consumers', params);
  return res.data;
};
