/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    id: string;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ id, children, ...props }, ref) => (
    <div className="a-checkbox">
      <div className="border" />
      <input type="checkbox" ref={ref} {...props} id={id} />
      <label htmlFor={id}>{children}</label>
    </div>
  ),
);

export default Checkbox;
