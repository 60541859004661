import axiosInstance from 'services/common/instance';

export type Districts = {
  name: string;
  districts: Record<string, string>;
}

export type CitiesListResponse = Record<string, Districts>;

export const getCitiesListService = async (): Promise<CitiesListResponse> => {
  const res = await axiosInstance.get('/consumers/cities');
  return res.data.data;
};

export const placeholder = null;
