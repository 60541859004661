import React from 'react';

import Complete from 'pages/Complete';
import Home from 'pages/Home';
import Preview from 'pages/Preview';
import RequestOTP from 'pages/RequestOTP';
import Scan from 'pages/Scan';
import Staff from 'pages/Staff';
import StartNow from 'pages/StartNow';

type Route = {
  id: number;
  path: string;
  component: React.FC;
}

const routes: Route[] = [
  {
    id: 1,
    path: '/',
    component: Home,
  },
  {
    id: 2,
    path: '/ar',
    component: Scan,
  },
  {
    id: 3,
    path: '/complete',
    component: Complete,
  },
  {
    id: 4,
    path: '/request-otp',
    component: RequestOTP,
  },
  {
    id: 5,
    path: '/preview',
    component: Preview,
  },
  {
    id: 6,
    path: '/start',
    component: StartNow,
  },
  {
    id: 7,
    path: '/staff',
    component: Staff,
  },
];

export default routes;
