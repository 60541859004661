import React from 'react';

import Text from 'components/Text';

export const RulePopup: React.FC = () => (
  <div className="modal-rule">
    <div className="title">
      <Text type="p" modifiers={['center', 'metallic', 't2']}>
        THỂ LỆ CHƯƠNG TRÌNH
      </Text>
    </div>
    <div className="panel">
      <Text type="p" modifiers={['center', 'yale-blue', 't3']}>
        TRẢI NGHIỆM THỰC TẾ ẢO CÙNG ENSURE GOLD GREEN HEALTH
      </Text>
    </div>

    {/* Rule: 1 */}
    <div className="rule">
      <Text type="p" modifiers={['metallic', 'p3', 'uppercase']}>
        1. TÊN CHƯƠNG TRÌNH: &quot;TRẢI NGHIỆM THỰC TẾ ẢO CÙNG ENSURE GOLD GREEN HEALTH&quot;:
      </Text>
      <Text type="p" modifiers={['yale-blue', 'p3']}>
        Sau đây được gọi tắt là &quot;chương trình&quot;.
      </Text>
    </div>

    {/* Rule: 2 */}
    <div className="rule">
      <Text type="p" modifiers={['metallic', 'p3', 'uppercase']}>
        2. TÊN THƯƠNG NHÂN THỰC HIỆN CHƯƠNG TRÌNH:
      </Text>
      <Text type="p" modifiers={['yale-blue', 'p3']}>
        {' '}
        Công Ty TNHH Dinh Dưỡng 3A (Việt Nam), Centec Tower 72-74 Nguyễn Thị
        Minh Khai, Phường Võ Thị Sáu, Quận 3, Thành Phố Hồ Chí Minh. Sau đây
        được gọi tắt là &quot;BTC&quot;
      </Text>
    </div>

    {/* Rule: 3 */}
    <div className="rule">
      <Text type="p" modifiers={['metallic', 'p3', 'uppercase']}>
        3. THỜI GIAN & PHẠM VI THỰC HIỆN CHƯƠNG TRÌNH:
      </Text>
      <Text type="p" modifiers={['yale-blue', 'p3']}>
        Chương trình áp dụng trên toàn quốc từ ngày 22/08/2021 đến hết ngày
        31/10/2021.
      </Text>
    </div>

    {/* Rule: 4 */}
    <div className="rule">
      <Text type="p" modifiers={['metallic', 'p3', 'uppercase']}>
        4. ĐỐI TƯỢNG THAM GIA HỢP LỆ:
      </Text>
      <Text type="p" modifiers={['yale-blue', 'p3']}>
        Mọi công dân Việt Nam từ 25 tuổi (sinh năm 1996 trở về trước) đang sinh
        sống tại Việt Nam.
      </Text>
    </div>

    {/* Rule: 5 */}
    <div className="rule">
      <Text type="p" modifiers={['metallic', 'p3', 'uppercase']}>
        5. ĐỐI TƯỢNG KHÔNG ĐƯỢC THAM GIA:
      </Text>
      <Text type="p" modifiers={['yale-blue', 'p3']}>
        Nhân viên văn phòng đại diện Abbott, công ty TNHH Dinh dưỡng 3A Việt
        Nam, công ty TNHH Aegis Media Viet Nam, công ty TNHH truyền thông MMS
        Việt Nam, quan chức chính phủ và các chuyên gia trong lĩnh vực chăm sóc
        sức khỏe (cụ thể ở Mục 9) không được tham gia chương trình này.
      </Text>
    </div>

    {/* Rule: 6 */}
    <div className="rule">
      <Text type="p" modifiers={['metallic', 'p3', 'uppercase']}>
        6. HÌNH THỨC:
      </Text>
    </div>
    <div className="rule-description">
      <ul className="rule-description_custom">
        <li>
          <Text type="p" modifiers={['yale-blue', 'p3']}>
            Người tham gia không cần mua sản phẩm để tham gia chương trình trải
            nghiệm này.
          </Text>
        </li>
        <li>
          <Text type="p" modifiers={['yale-blue', 'p3']}>
            Khách hàng tham gia Chương trình &quot;TRẢI NGHIỆM THỰC TẾ ẢO CÙNG
            ENSURE GOLD GREEN HEALTH&quot; cách thực hiện các bước sau:
          </Text>
        </li>
        <li>
          <Text type="p" modifiers={['yale-blue', 'p3']}>
            Bước 1: Đăng nhập vào trang
            {' '}
            <a href="https://plantbased.hoithaoensure.com" className="link">
              https://plantbased.hoithaoensure.com
            </a>
            {' '}
            hoặc quét mã QR trên nhãn dán được đính kèm trên sản phẩm Ensure
            Gold hay các biển quảng cáo trên mạng xã hội hoặc kênh thương mại
            điện tử để trải nghiệm ứng dụng AR
          </Text>
        </li>
        <li>
          <Text type="p" modifiers={['yale-blue', 'p3']}>
            Bước 2: Khách hàng tiến hành thêm bước nhập thông tin khách để được
            nhận 1 mẫu thử Ensure Gold Green Health 60.6g miễn phí.
          </Text>
        </li>
      </ul>
    </div>

    {/* Rule: 7 */}
    <div className="rule">
      <Text type="p" modifiers={['metallic', 'p3', 'uppercase']}>
        7. QUÀ TẶNG:
      </Text>
      <Text type="p" modifiers={['yale-blue', 'p3']}>
        Tất cả các khách hàng có đăng ký hợp lệ sẽ nhận được: Quà tặng liền tay
        là 01 GÓI 60,6g ENSURE GOLD Green Health Đạm thực vật chất lượng cao
        mới; được giao tận nhà hoàn toàn miễn phí trên toàn quốc.
      </Text>
    </div>

    {/* Rule: 8 */}
    <div className="rule">
      <Text type="p" modifiers={['metallic', 'p3', 'uppercase']}>
        8. NỘI DUNG CHI TIẾT VÀ THỂ LỆ CHƯƠNG TRÌNH:
      </Text>
    </div>
    <div className="rule-description">
      {/* Rule: 8.1 */}
      <div className="rule-description_sub">
        <Text type="span" modifiers={['yale-blue', 'p3']}>
          8.1
        </Text>
        <Text type="span" modifiers={['yale-blue', 'p3']}>
          Các thức tham gia chương trình:
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Người tham gia không cần mua sản phẩm để tham gia chương trình trải
          nghiệm này.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Khách hàng tham gia Chương trình &quot;TRẢI NGHIỆM THỰC TẾ ẢO CÙNG
          ENSURE GOLD GREEN HEALTH&quot; cách thực hiện các bước sau:
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Bước 1: Đăng nhập vào trang https://plantbased.hoithaoensure.com
          hoặc quét mã QR trên nhãn dán được đính kèm trên sản phẩm Ensure Gold
          hay các biển quảng cáo trên mạng xã hội hoặc kênh thương mại điện tử
          để trải nghiệm ứng dụng AR
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Bước 2: Khách hàng tiến hành thêm bước nhập đầy đủ và chính xác
          thông tin cá nhân đểđược nhận 1 mẫu thử Ensure Gold Green Health 60.6g
          miễn phí.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - BTC sẽ liên lạc với những khách hàng có đăng ký hợp lệ để xác nhận
          thông tin và giao Gói dùng thử.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Mỗi số điện thoại chỉ được đăng ký tham gia chương trình một lần duy
          nhất. Mỗi người tham gia hợp lệ được nhận 1 Gói dùng thử và cơ hội
          trúng một (01) giải thưởng duy nhất trong toàn bộ chương trình.
        </Text>
      </div>
      {/* Rule: 8.2 */}
      <div className="rule-description_sub">
        <Text type="span" modifiers={['yale-blue', 'p3']}>
          8.2
        </Text>
        <Text type="span" modifiers={['yale-blue', 'p3']}>
          Cách thức xác định tính hợp lệ tham gia chương trình:
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Người tham gia chương trình hợp lệ phải là người thỏa đúng điều kiện
          tham gia chương trình ở mục 4, với thông tin trùng khớp với các thông
          tin khi đăng ký tham gia.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Chương trình không áp dụng với những khách hàng đã từng đăng ký
          thông tin tham gia các chương trình của Ensure Gold (Bao gồm các hoạt
          động như đăng ký mua hàng, tham gia các hoạt động khuyến mãi/đổi quà,
          hoặc đã từng nhận được gói dùng thử) trong vòng 12 tháng gần nhất kể
          từ ngày bắt đầu chương trình này.
        </Text>
      </div>
      {/* Rule: 8.3 */}
      <div className="rule-description_sub">
        <Text type="span" modifiers={['yale-blue', 'p3']}>
          8.3
        </Text>
        <Text type="span" modifiers={['yale-blue', 'p3']}>
          Cách thức trao Gói dùng thử:
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - BTC sẽ liên lạc với các khách hàng có đăng ký hợp lệ để xác nhận
          thông tin trước khi giao thưởng. Nếu BTC không thể liên lạc với người
          tham gia sau 3 lần gọi, khách hàng sẽ mất quyền nhận quà tặng.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Giải thưởng sẽ được gửi đến khách hàng đăng ký hợp lệ qua đường bưu
          điện, đến trực tiếp địa chỉ theo thông tin người tham gia đã cung cấp
          trong vòng 7 - 14 ngày kể từ ngày Bộ phận chăm sóc khách hàng liên hệ
          xác nhận đăng ký/thông báo trúng thưởng qua điện thoại.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Công ty đối tác giao hàng của Abbott sẽ kiểm tra giấy CMND khi giao
          hàng. Trường hợp người tham gia cung cấp thông tin đăng ký không đúng
          thông tin theo giấy tờ sẽ không đủ điều kiện để nhận gói dùng
          thử/ giải thưởng.
        </Text>
      </div>
      {/* Rule: 8.4 */}
      <div className="rule-description_sub">
        <Text type="span" modifiers={['yale-blue', 'p3']}>
          8.4
        </Text>
        <Text type="span" modifiers={['yale-blue', 'p3']}>
          Tổng đài hỗ trợ:
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          Để biết thêm thông tin chi tiết của chương trình, người tham gia có thể
          gọi đến tổng đài 19001519. Thời gian hoạt động từ Thứ 2 đến Thứ 6 từ
          8h30 sáng đến 5h30 chiều trong suốt thời gian thực hiện chương trình.
          Nhân viên Abbott sẽ hỗ trợ tư vấn và giải đáp thắc mắc cho người tham
          gia các thông tin liên quan đến chương trình.
        </Text>
      </div>
      {/* Rule: 8.5 */}
      <div className="rule-description_sub">
        <Text type="span" modifiers={['yale-blue', 'p3']}>
          8.5
        </Text>
        <Text type="span" modifiers={['yale-blue', 'p3']}>
          Các quy định khác:
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Khi tham gia chương trình, người tham gia đã đồng ý với các điều
          khoản, điều kiện, quy định (nếu có) của BTC.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Người tham gia chương trình cam kết hoàn toàn chịu mọi trách nhiệm
          pháp lý hay thiệt hại cá nhân (nếu có) khi có bất kỳ sự việc nào phát
          sinh từ hoặc có liên quan đến việc tham gia chương trình.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Trên website và Thể lệ chương trình bao gồm nội dung: &quot;Bằng
          việc nhấn nút tham gia, tôi đồng ý cung cấp thông tin cá nhân của tôi
          (&quot;Thông tin cá nhân&quot;) cho BTC (&quot;Bên tổ chứcchương
          trình&quot;). Để phục vụ cho hoạt động của BTC, tôi đồng ý và xác nhận
          là: (i) BTC được quyền thu thập, lưu trữ, xử lý, sử dụng, mã hoá và/
          hoặc chia sẻ Thông tin cá nhân cho các công ty trong cùng tập đoàn
          và/hoặc các bên thứ ba do BTC chỉ định và (ii) khi được BTC cho phép,
          các công ty trong cùng tập đoàn và các bên thứ ba do BTC chỉ định được
          quyền thu thập, lưu trữ, xử lý, sử dụng và/ hoặc mã hoá Thông tin cá
          nhân do BTC chia sẻ.&quot;
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - BTC có quyền sử dụng thông tin của người tham gia vào các hoạt động
          kinh doanh, quảng cáo, khuyến mãi và tiếp thị sau khi có sự đồng ý của
          người tham gia thông qua việc nhấn nút tham gia mua hàng & đồng ý với
          Thể lệ chương trình.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Trường hợp bất khả kháng do thiên tai, lũ lụt, sét đánh, khủng bố,
          bị tấn công máy chủ làm thất thoát dữ liệu đăng ký của người tham gia,
          BTC giữ quyền quyết định thay đổi hoặc hủy bỏ chương trình và thông
          báo với người tham dự trong thời gian sớm nhất sau khi Thông báo đến
          cơ quan quản lý Nhà nước về thương mại có thẩm quyền.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - BTC giữ quyền chính được tạm dừng hay thay đổi nội dung chương
          trình, hay điều chỉnh thể lệ cho phù hợp với tình hình thực tế vào bất
          kì thời điểm nào trước khi kết thúc chương trình bằng cách đăng tải
          thể lệ đã chỉnh sửa trên trang web.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - BTC có toàn quyền loại bỏ người tham gia ra khỏi chương trình mà
          không cần thông báo trước nếu BTC phát hiện người tham gia đó thực
          hiện bất cứ hành động nào gây ảnh hưởng đến kết quả cũng như tính
          trung thực của chương trình. BTC sẽ gửi mail hoặc tin nhắn từ chối
          tham gia chương trình thông báo đến khách hàng và cơ quan quản lý nhà
          nước về thương mại có thẩm quyền.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Trong trường hợp BTC nghi ngờ người tham gia có dấu hiệu gian lận
          hoặc có mục đích hoặc hành vi cố tình vi phạm thể lệ trong quá trình
          tham gia chương trình thì BTC có toàn quyền loại bỏ người này ra khỏi
          chương trình mà không cần thông báo trước. BTC sẽ gửi mail hoặc tin
          nhắn từ chối tham gia chương trình thông báo đến khách hàng và cơ quan
          quản lý nhà nước về thương mại có thẩm quyền.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Bất kỳ người tham dự nào vi phạm những quy định và thể lệ của chương
          trình đều sẽ bị loại.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Trong trường hợp phát sinh tranh chấp, khiếu nại liên quan đến
          chương trình, BTC sẽ đứng ra trực tiếp giải quyết và quyết định của
          BTC là kết quả cuối cùng.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Mọi thắc mắc vui lòng liên hệ tổng đài 1900 1519.
        </Text>
        <Text type="p" modifiers={['yale-blue', 'p3']}>
          - Để ngừng tham gia chương trình, vui lòng gọi 19001519 (1.000
          đồng/phút) hoặc gửi tin nhắn đến Facebook Ensure Gold Việt Nam tại
          {' '}
          <a
            href="https://www.facebook.com/ensuregoldvietnam/"
            className="link"
          >
            www.facebook.com/ensuregoldvietnam/
          </a>
          .
        </Text>
      </div>
    </div>

    {/* Rule: 9 */}
    <div className="rule">
      <Text type="p" modifiers={['metallic', 'p3', 'uppercase']}>
        9. MỘT SỐ ĐỊNH NGHĨA:
      </Text>
    </div>
    <div className="rule-description">
      <Text type="p" modifiers={['yale-blue', 'p3']}>
        a. &quot;Quan Chức Chính Phủ&quot;: Bất kỳ người nào được tuyển dụng bởi
        một tổ chức do bất kỳ cấp chính phủ nào sở hữu, kiểm soát, tài trợ hoặc
        điều hành tại mọi quốc gia, cũng như bất kỳ người nào hành động với tư
        cách đại lý hoặc đại diện của chính phủ. Ví dụ về Quan Chức Chính Phủ
        liên quan tới việc kinh doanh của Abbott Việt Nam bao gồm trưởng phòng
        vật tư của bệnh viện công, người đại diện của cơ quan quản lý và phòng
        ban chính phủ, cán bộ được bầu chọn, và các quan chức hoặc quản trị viên
        thuộc các dịch vụ của chính phủ như cấp phép, đăng ký, hải quan và dịch
        vụ tiện ích.
      </Text>
      <br />
      <Text type="p" modifiers={['yale-blue', 'p3']}>
        b. &quot;Chuyên Gia Chăm Sóc Sức Khỏe&quot;: Bất kỳ chuyên gia được cấp
        phép y tế hoặc được đào tạo về khoa học nào sử dụng hoặc dự kiến sử dụng
        giấy phép hoặc nội dung đào tạo đó vào công việc của họ để cung cấp dịch
        vụ chăm sóc sức khỏe (ví dụ bao gồm bác sĩ, dược sĩ, y tá, nhà nghiên cứu
        và nhân viên phòng thí nghiệm), cũng như bất kỳ người nào ra quyết định
        mua hoặc sử dụng cho một Tổ chức chăm sóc sức khỏe (ví dụ bao gồm
        quản trị viên hoặc nhân viên mua sắm vật tư của các cơ sở y tế) về sản
        phẩm của Abbott Việt Nam. Định nghĩa này được áp dụng cho dù quy định về
        chăm sóc sức khỏe ở đâu, dù là trong một cơ sở tư nhân, hay do chính phủ
        sở hữu hoặc liên kết với chính phủ. Một số Chuyên gia chăm sóc sức khỏe
        do các tổ chức công tuyển dụng có thể được coi là Quan Chức Chính Phủ.
      </Text>
    </div>
  </div>
);

export const placeholde = null;
