/* eslint-disable default-case */
/* eslint-disable func-names */
/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
export const detectMaker = (videoEl: HTMLVideoElement) => {
  let loadingResolve: () => void;
  let detectResolve: () => void;

  const camera_para = '/ar/artoolkit/camera_para.dat';
  const pw = videoEl.videoWidth;
  const ph = videoEl.videoHeight;

  const canvas_process = document.createElement('canvas');
  canvas_process.width = pw;
  canvas_process.height = ph;

  const context_process = canvas_process.getContext('2d')!;

  const worker = new Worker('/ar/artoolkit/artoolkit.wasm_worker.js');

  const result: [Promise<void>, Promise<void>, () => void] = [
    new Promise<void>((r) => loadingResolve = r),
    new Promise<void>((r) => detectResolve = r),
    () => { worker.terminate(); },
  ];

  worker.onmessage = function (ev) {
    const msg = ev.data;
    let foundMarker = false;

    switch (msg.type) {
      case 'wasm': {
        worker.postMessage({
          type: 'load',
          pw,
          ph,
          camera_para,
          marker: '/ar/marker/plantbase-tag',
        });
        break;
      }

      case 'loaded': {
        break;
      }

      case 'endLoading': {
        loadingResolve();
        break;
      }

      case 'found': {
        foundMarker = true;
        found(msg);
        break;
      }
      case 'not found': {
        found(null);
        break;
      }
    }

    if (!foundMarker) {
      const processTime = Date.now() - lastTime;
      setTimeout(() => process(), Math.max(0, 100 - processTime));
    }
  };

  const found = function (msg: any) {
    if (msg) {
      detectResolve();
    }
  };

  let lastTime = Date.now();

  function process() {
    lastTime = Date.now();

    context_process.drawImage(videoEl, 0, 0);

    const imageData = context_process.getImageData(0, 0, pw, ph);
    worker.postMessage({ type: 'process', imagedata: imageData }, [
      imageData.data.buffer,
    ]);
  }

  process();

  return result;
};

export default detectMaker;
