/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';

import mapModifiers from 'utils/functions';

export interface inputProps extends React.InputHTMLAttributes<HTMLInputElement>{
  variant?: 'normal';
}

const Input = forwardRef<
  HTMLInputElement,
  inputProps
>(({ variant, ...rest }, ref) => <div className={mapModifiers('a-input', variant)}><input className="a-input_input" ref={ref} {...rest} /></div>);

export const InputNumber = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ onChange, ...props }, ref) => {
  const onChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.match(/\D+/)) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.replace(/\D+/g, '');
    }
    if (onChange) onChange(event);
  };

  return <Input ref={ref} onChange={onChangeNumber} {...props} />;
});

Input.defaultProps = {
  autoComplete: 'off',
  type: 'text',
};

export default Input;
