/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import Select, {
  components,
  GroupedOptionsType,
  OptionsType,
  Props as SelectProps,
} from 'react-select';

import iconCaretDown from 'assets/icons/arrow_down.svg';

export type OptionType = {
  value: string | number,
  label: string
}
interface PulldownProps extends SelectProps {
  options?: OptionsType<any> | GroupedOptionsType<any>;
}

const CaretDownNormalIcon = ({ menuIsOpen }: any) => (
  <img
    src={iconCaretDown}
    alt="icon-arrow"
    style={{
      transform: menuIsOpen && 'rotate(180deg)',
      transition: 'all 0.3s ease',
    }}
  />
);

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <CaretDownNormalIcon menuIsOpen={props.selectProps.menuIsOpen} />
  </components.DropdownIndicator>
);

const Pulldown = forwardRef<HTMLDivElement, PulldownProps>(
  ({
    options, ...innerProps
  }, ref) => (
    <div className="m-pulldown">
      <Select
        {...innerProps}
        options={options}
        noOptionsMessage={() => 'Không tìm thấy'}
        styles={{
          singleValue: (base: any) => ({
            ...base,
            color: '#6d6e70',
            fontWeight: 'bold',
          }),
          control: (base: any) => ({
            ...base,
            height: '40px',
            minHeight: '40px',
            paddingLeft: '8px',
            paddingRight: '6px',
            color: '#000',
            fontSize: '14px',
            fontWeight: 'bold',
            boxShadow: 'inset 0 0 4px rgb(0, 0, 0, 0.7)',
            borderRadius: '8px',
            borderWidth: '1px',
            borderColor: '#3BA558',
            background: '#E7E8E8',
            '&:hover': {
              borderColor: '#3BA558',
            },
            cursor: 'pointer',
          }),
          placeholder: (base: any) => ({
            ...base,
            color: '#AEAEAE',
            marginLeft: '10px',
          }),
          valueContainer: (base: any) => ({
            ...base,
            paddingLeft: 0,
            paddingRight: 0,
          }),
          option: (base: any, state) => ({
            ...base,
            display: 'flex',
            alignItems: 'center',
            height: '36px',
            cursor: 'pointer',
            fontWeight: state.isSelected ? '500' : '400',
            color: state.isSelected ? '#3BA558' : '#6d6e70',
            backgroundColor:
              state.isSelected || state.isFocused ? '#F7F9FA' : 'transparent',
            '&:hover': {
              backgroundColor: '#F7F9FA',
            },
          }),
          menu: (provided: any) => ({ ...provided, zIndex: 4 }),
          menuList: (provided: any) => ({
            ...provided,
            paddingTop: '12px',
            paddingBottom: '12px',
            fontSize: '14px',
            maxHeight: 200,
            backgroundColor: '#fff',
            borderRadius: 4,
            '::-webkit-scrollbar': {
              width: 8,
            },
            '::-webkit-scrollbar-track': {
              background: '#F2F8FB',
            },
            '::-webkit-scrollbar-thumb': {
              background: '#3BA558',
              borderRadius: 20,
            },
          }),
          dropdownIndicator: (provided: any) => ({
            ...provided,
            padding: '0',
            color: '#fff',
          }),
        }}
        components={{
          DropdownIndicator: (props) => DropdownIndicator(props),
          IndicatorSeparator: () => null,
        }}
        inputRef={ref}
      />
    </div>
  ),
);

export default Pulldown;
