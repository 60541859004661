import React from 'react';
import { useHistory } from 'react-router';

import Abbott from 'assets/images/Abbott.png';
import Banner from 'assets/images/banner.png';
import Button from 'components/Button';
import Image from 'components/Image';
import Text from 'components/Text';

const StartNow: React.FC = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: '/request-otp',
      search: window.location.search,
    });
  };

  return (
    <div className="p-start">
      <div className="container">
        <div className="banner">
          <Image imgSrc={Banner} alt="banner" ratio="banner" />
        </div>

        <div className="sub-banner">
          <Text type="p" modifiers={['silver-sand', 'c1', 'center']}>*Trong số các sản phẩm Ensure Gold</Text>
        </div>

        <div className="title">
          <Text type="span" modifiers={['gold', '700', 't1', 'center']}>
            CHÚC MỪNG
          </Text>
        </div>

        <div className="subtext">
          <Text type="p" modifiers={['t2', '700', 'white', 'center']}>
            BẠN NHẬN ĐƯỢC 1 PHẦN QUÀ TỪ
            <br />
            <Text type="span" modifiers={['mediumSeaGreen', '700', 'center', 't2']}>
              ENSURE GOLD GREEN HEALTH
            </Text>
          </Text>
        </div>

        <div className="controls">
          <Button
            type="button"
            onClick={handleClick}
          >
            <div>
              <Text type="p" modifiers={['white', 'p1', 'uppercase']}>
                điền thông tin
              </Text>
              <Text type="p" modifiers={['white', 'p1', 'uppercase']}>
                nhận quà
              </Text>
            </div>
          </Button>
        </div>

        <div className="bottom adjust-flex">
          <Text type="p" modifiers={['taupeGray']}>
            ENS-C-466-21
          </Text>
          <div className="abbott">
            <Image ratio="abbott" imgSrc={Abbott} alt="Abbott" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartNow;
