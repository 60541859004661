import React from 'react';

import mapModifiers from 'utils/functions';

const iconList = {
  loading: 'loading',
  loadingBlack: 'loadingBlack',
  saveAlt: 'saveAlt',
  share: 'share',
  cameraAlt: 'cameraAlt',
  giftCard: 'giftCard',
  arrowDownWhite: 'arrowDownWhite',
  rotate: 'rotate',
};

export type IconName = keyof typeof iconList;

interface IconProps {
  iconName: IconName;
}

const Icon: React.FC<IconProps> = ({ iconName }) => (
  <div className={mapModifiers('a-icon', iconName)} />
);

export default Icon;
