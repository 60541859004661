import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-5D3F3ZJ',
};

export const initGTM = (): void => {
  TagManager.initialize(tagManagerArgs);
};

export default initGTM();
